<template>
  <yandex-map
    :settings="settings"
    ref="ymaps"
    class="ymap"
    :zoom="zoom"
    :behaviors="['drag']"
    :controls="['zoomControl', 'typeSelector']"
    :map-type="type"
    :coords="center"
    :cluster-options="clusterOptions"
    @actionend="$emit('actioned', $event)"
  >
    <ymap-marker
      v-for="(marker, index) in placemarks"
      :key="index"
      :coords="marker.coordinates"
      :marker-id="index"
      :icon="marker.coordinates[0] === center[0] && marker.coordinates[1] === center[1] ? markerIconActive : markerIconDefault"
      @click="$emit('getPointInfo', marker)"
      :cluster-name="isMaxZoomed ? null : 'all'"
    />
  </yandex-map>


</template>


<script setup>
import { defineProps, defineEmits, ref, reactive, computed } from 'vue'
import { yandexMap, ymapMarker, loadYmap } from 'vue-yandex-maps'
import image from "@/assets/images/y-map-marker-default.svg"
import image2 from "@/assets/images/y-map-marker-active.svg"
import image3 from "@/assets/images/y-map-marker-other.svg"


const clusterOptions = computed(()=> {
  return {
      all: {
        groupByCoordinates: props.isMaxZoomed ? true : false,
        clusterDisableClickZoom: false,
        preset: 'islands#darkGreenClusterIcons',
      }
    }
})


const props = defineProps({
  center: {
    type: Array,
    default: ()=> []
  },
  zoom: {
    type: Number,
    default: ()=> 12
  },
  type: {
    type: String,
    default: ()=> 'satellite'
  },
  placemarks: {
    type: Array,
    default: ()=> []
  },
  containerPoint: {
    default: false
  },
  isMaxZoomed: {
    type: Boolean,
  }
})
const emit = defineEmits(['getPointInfo', 'actioned'])

const settings = reactive({
  apiKey: process.env.VUE_APP_YANDEX_MAP_API,
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1'
})

const markerIconDefault = computed(() => {
  return {
    layout: 'default#image',
    imageHref: props.containerPoint ? image3 : image,
    imageSize: props.containerPoint ? [14, 14] : [33, 37],
    imageOffset: props.containerPoint ? [-7, -7] : [-16, -18],
  }
})

const markerIconActive = {
  layout: 'default#image',
  imageHref: image2,
  imageSize: [32, 36],
  imageOffset: [-16, -18],
}
</script>

<style scoped lang="scss">
.ymap{
  width: 100%;
  height: 380px;
  border-radius: 36px 36px 0 0;
  border-radius: 20px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
</style>
