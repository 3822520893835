<template>
  <ECModal
    @close="$emit('close')"
    title="Сообщить об ошибке"
  >
    <form id="error-map" class="content" @submit.prevent="">

      <div class="form-item">
        <label class="required">Адрес</label>
        <ECInput
          v-model="address"
          placeholder="Введите адрес"
          required
        />
        <transition name="default">
          <div class="dadata dadata--address" v-if="daDataArrAddress.length && isShowDaDataMenuAddress">
            <div class="dadata-item"
              v-for="item in daDataArrAddress"
              :key="item.value"
              @click="setDaDataAddress(item)"
            >
              <span>{{item.value}}</span>
            </div>
          </div>
        </transition>
      </div>

      <div class="form-item">
        <label class="required">Комментарий</label>
        <ECInput
          v-model="comment"
          required
        />
      </div>

      <ECButton
        label="Отправить"
        view="primary"
        :disabled="!validate"
        @click="sendForm"
      />

    </form>
  </ECModal>
</template>

<script setup>
import { defineEmits, ref, watchEffect, computed } from 'vue'
import useApi from '@/services/useApi'

const emit = defineEmits(['close'])

const address = ref('')
const addressPayload = ref({})
const daDataArrAddress = ref([])
const isShowDaDataMenuAddress = ref(false)
const flagDadata = ref(true)

const comment = ref('')

const setDaDataAddress = (x) => {
  addressPayload.value = x
  address.value = x.value
  isShowDaDataMenuAddress.value = false
  flagDadata.value = false
}

const sendForm = async() => {
  const req = await useApi('post', 'requests/create/container_error', {
    addr: address.value,
    comment: comment.value
  })
  emit('close')
}

watchEffect(async() => {
  if(address.value.length >= 3 && flagDadata.value){
    const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address"
    const token = "f94a30447c5eaf823177a205adbcd6df33603ba9"
    const options = {
      method: "POST",
      mode: "cors",
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + token
      },
      body: JSON.stringify({
        query: address.value,
        restrict_value: true,
        locations: [{kladr_id: "0200000000000"}],
        locations_boost: [{kladr_id: "7800000000000"}],
        count: 10
      })
    }
    const data = await fetch(url, options)
    const dd = await data.json()
    daDataArrAddress.value = dd.suggestions

    isShowDaDataMenuAddress.value = true
  } else {
    daDataArrAddress.value = []
  }
  flagDadata.value = true
})

const validate = computed(()=> addressPayload.value.data && comment.value.length > 3)

</script>

<style scoped lang="scss"></style>
