<template>
  <div class="page container">
    <div class="page-title">Контейнерные площадки</div>

    <div class="search-block">
      <img class="zoom-map-icon" src="@/assets/images/zoom-map.svg">

      <transition name="default">
        <div
          class="map-btn"
          v-if="currentType === 'id' && address.length"
          @click="getMarkers"
        >
          Поиск
        </div>
      </transition>
      <ECInput
        v-model="address"
        placeholder="Поиск по адресу или ID конт. площадки"
        required
        :maxLength="currentType === 'id' ? '8' : 'auto'"
      />
      <transition name="default">
        <div class="dadata dadata--address" v-if="daDataArrAddress.length && isShowDaDataMenuAddress">
          <div class="dadata-item"
            v-for="item in daDataArrAddress"
            :key="item.value"
            @click="setDaDataAddress(item)"
          >
            <span>{{item.value}}</span>
          </div>
        </div>
      </transition>
    </div>

    <div class="map-container">
      <ECYandexMap
        :center="mapStateCenter"
        :zoom="mapStateZoom"
        :type="mapStateType"
        :placemarks="mapStatePlacemarks"
        @getPointInfo="getPointInfo"
        :containerPoint="containerPoint"
        @actioned="actioned"
        :isMaxZoomed="isMaxZoomed"
      />

      <div class="found-error-btn" @click="isShowECModalFoundErrorMap = true">Нашли ошибку?  Сообщите</div>

      <ECModalFoundErrorMap
        v-if="isShowECModalFoundErrorMap"
        @close="isShowECModalFoundErrorMap = false"
      />

      <transition name="default">
        <ECModalFoundErrorMap
          v-if="isShowECModalFoundErrorMap"
          @close="isShowECModalFoundErrorMap = false"
        />
    </transition>
    </div>


    <div v-if="emptyReq" class="empty-content">По вашему запросу ничего не найдено</div>

    <div
      class="platform-info"
      v-if="containerPoint"
    >

      <div class="platform-info__title">Контейнерная площадка №{{ containerPoint.number }}</div>

      <div class="platform-info__info-block">
        <div class="row">
          <div class="item">
            <span>Адрес</span>
            <p>{{ containerPoint.addr }}</p>
          </div>

          <div class="item">
            <span>Название</span>
            <p>{{ containerPoint.name }}</p>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <span>Координаты</span>
            <p>{{ containerPoint.coordinates[0] }}, {{ containerPoint.coordinates[1] }}</p>
          </div>
          <div class="item">
            <span>Тип(Статус)</span>
            <p>{{ containerPoint.type }}</p>
          </div>
        </div>
      </div>

      <div class="platform-info__title">Фото</div>

      <div
        v-if="containerPoint.photo"
        class="platform-info__gallery"
      >
        <img
          v-if="containerPoint.photo[0]"
          :src="containerPoint.photo[0]"
          @click="showGallery(0)"
        >
        <img
          v-if="containerPoint.photo[1]"
          :src="containerPoint.photo[1]"
          @click="showGallery(1)"
        >
      </div>
      <div v-else class="no-info">Информация не найдена</div>

      <div class="platform-info__title">Контейнеры</div>

      <div
        class="platform-info__containers"
        v-if="containerPoint.containers"
      >
        <div
          class="item"
          v-for="(container, idx) in containerPoint.containers"
          :key="container.id"
        >
          <div class="left">
            <span class="num">{{ idx + 1 }}</span>
            <div class="description">
              <span>Тип</span>
              <p>{{ container.type }}</p>
            </div>
          </div>
          <div class="right">
            <div class="description adapt">
              <span>Тип</span>
              <p>{{ container.type }}</p>
            </div>
            <div class="description">
              <span>График вывоза</span>
              <p>{{ container.timig }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-info">Информация не найдена</div>

      <div class="platform-info__title">Последние 10 вывозов</div>

      <div class="loading" v-if="reports === null" >Загрузка</div>

      <div
        v-if="reports !== null && reports.length"
        class="platform-info__last-picks"
      >
        <ECReportitem
          v-for="report in reports"
          :key="report.number"
          :report="report"
        />
      </div>
      <div v-if="reports !== null && !reports.length" class="no-info">Информация не найдена</div>

    </div>

    <transition name="default">
      <ECModalConatainersGallery
        v-if="isShowECModalConatainersGallery && containerPoint && containerPoint.photo"
        @close="isShowECModalConatainersGallery = false"
        :gallery="containerPoint.photo"
        :order="orderGallery"
        @setOrder="orderGallery = $event"
      />
    </transition>

    <transition name="default">
      <ECGlobalPreloader v-if="preloader"/>
    </transition>
  </div>
</template>

<script setup>
import { ref, reactive, watchEffect, onMounted, defineEmits, computed } from 'vue'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router'
import ECYandexMap from '@/components/common/ECYandexMap.vue'
import useApi from '@/services/useApi'
import ECModalConatainersGallery from '@/components/modals/ECModalConatainersGallery.vue'
import ECReportitem from '@/components/common/ECReportItem.vue'
import ECGlobalPreloader from '@/components/common/ECGlobalPreloader.vue'
import ECModalFoundErrorMap from '@/components/modals/ECModalFoundErrorMap.vue'

const route = useRoute()
const router = useRouter()

const isShowECModalFoundErrorMap = ref(false)

const preloader = ref(false)

const reports = ref(null)

const eMap = ref(null)

const isMaxZoomed = ref(false)

const actioned = e => {
  eMap.value = e

  if(e.originalEvent.map._zoom >= 19){
    isMaxZoomed.value = true
  } else {
    isMaxZoomed.value = false
  }

  if(e.originalEvent.map._zoom > 19){
    setTimeout(() => mapStateZoom.value = 19, 0);
  }
}

const mapStateCenter = ref([53.630483, 55.936476])
const mapStateType = ref('satellite')
const mapStateZoom = ref(12)
const mapStatePlacemarks = ref([])

const isShowECModalConatainersGallery = ref(false)
const orderGallery = ref(0)
const showGallery = (n) => {
  orderGallery.value = n
  isShowECModalConatainersGallery.value = true
}

const emptyReq = ref(false)

const flagDadata = ref(true)

const currentType = ref('id')

const address = ref('')

const addressPayload = ref({})

const daDataArrAddress = ref([])
const isShowDaDataMenuAddress = ref(false)


const containerPoint = ref(null)

const setDaDataAddress = x => {
  addressPayload.value = x
  address.value = x.value
  isShowDaDataMenuAddress.value = false
  flagDadata.value = false

  mapStateCenter.value = [x.data.geo_lat, x.data.geo_lon]
  mapStateZoom.value = 16

  preloader.value = true

  setTimeout(()=> {

    mapStateCenter.value = [x.data.geo_lat, x.data.geo_lon]
    mapStateZoom.value = 16

    setTimeout(()=> {
      getMarkers(eMap.value)
    }, 2000)

    getMarkers(eMap.value)
  }, 2000)
}


watchEffect(async() => {
  isNaN(Number(address.value)) ? currentType.value = 'coords' : currentType.value = 'id'

  if(address.value.length >= 3 && flagDadata.value && currentType.value === 'coords'){
    const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address"
    const token = "f94a30447c5eaf823177a205adbcd6df33603ba9"
    const options = {
      method: "POST",
      mode: "cors",
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + token
      },
      body: JSON.stringify({
        query: address.value,
        restrict_value: true,
        locations: [{kladr_id: "0200000000000"}],
        locations_boost: [{kladr_id: "7800000000000"}],
        count: 10
      })
    }
    const data = await fetch(url, options)
    const dd = await data.json()
    daDataArrAddress.value = dd.suggestions

    isShowDaDataMenuAddress.value = true
  } else {
    daDataArrAddress.value = []
  }
  flagDadata.value = true
})


const getMarkers = async(eMap) => {
  preloader.value = true
  emptyReq.value = false
  mapStatePlacemarks.value = []
  containerPoint.value = null

  if(currentType.value !== 'id'){ //multi container
    router.replace({ query: null })

    setTimeout(async()=>{
      const req = await useApi('post', 'container/get_by_coor', {
        coords: {
          lat_max: String(eMap.originalEvent.map._bounds[0][0]),
          lat_min: String(eMap.originalEvent.map._bounds[1][0]),
          ln_max: String(eMap.originalEvent.map._bounds[1][1]),
          ln_min: String(eMap.originalEvent.map._bounds[0][1])
        }
      })
      if(req.platforms.length){
        mapStatePlacemarks.value = req.platforms
      } else {
        emptyReq.value = true
        router.replace({ query: null })
      }
    }, 1000)
  }
  else { //solo container
    const req = await useApi('post', 'container/find', {
      radius: '100',
      pattern: address.value
    })

    if(req.platforms.length){
      mapStatePlacemarks.value = req.platforms
      mapStateCenter.value = req.platforms[0].coordinates
      getPointInfo(req.platforms[0])
    } else {
      emptyReq.value = true
      router.replace({ query: null })
    }
  }
  preloader.value = false
}


onMounted(()=> {
  if(route.query.container){
    address.value = route.query.container
    getMarkers()
  }
})


const getPointInfo = async(point) => {
  reports.value = null
  router.replace({ query: { container: point.number } })

  mapStateCenter.value = point.coordinates
  containerPoint.value = point

  setTimeout(()=> {
    mapStateZoom.value = 19
  }, 1000)


  const req = await useApi('get', `container/get_report/${point.number}`)
  reports.value = req.report
}

document.addEventListener('keypress', function (e) {
  if (e.keyCode === 13 && address.value.length && currentType.value === 'id'){
    getMarkers()
  }
});
</script>

<style scoped lang="scss">
.page-title{
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  padding-left: 16px;
}

.map-container{
  position: relative;
  .found-error-btn{
    position: absolute;
    left: 8px;
    bottom: 8px;
    padding: 8px 14px;
    background: $ec-grey;
    border-radius: 14px;
    font-size: 14px;
    line-height: 20px;
    color: rgba($ford-medium-dark, .7);
    cursor: pointer;
    display: none;
  }
}

.platform-info{
  background: white;
  padding: 25px;
  margin-top: 20px;
  border-radius: 20px;
  &__title{
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 18px;
  }
  &__info-block{
    margin-bottom: 10px;
    @media (max-width: 1100px){
      flex-wrap: wrap;
    }
    .row{
      display: flex;
      gap: 0 20px;
      @media (max-width: 1100px){
        flex-wrap: wrap;
      }
      .item{
        width: 50%;
        padding-bottom: 20px;
        border-bottom: 1px solid #F3F3F3;
        margin-bottom: 18px;
        @media (max-width: 1100px){
          width: 100%;
        }
        span{
          color: rgba(22, 22, 22, 0.5);
        }
        p{
          font-size: 16px;
          line-height: 24px;
          margin-top: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &:last-child{
        .item{
          border-bottom: none;
          margin-bottom: 0;
        }
      }
    }
  }

  &__gallery{
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
    @media (max-width: 1100px){
      flex-wrap: wrap;
    }
    img{
      width: 50%;
      height: 180px;
      border-radius: 12px;
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      cursor: pointer;
      @media (max-width: 1100px){
        width: 100%;
        height: auto;
      }
    }
  }

  &__containers{
    margin-bottom: 30px;
    .item{
      margin-bottom: 16px;
      display: flex;
      gap: 0 20px;
      .left,
      .right{
        width: 50%;
        display: flex;
        @media (max-width: 1100px){
          flex-direction: column;
          width: auto;
        }
        .num{
          margin-right: 20px;
          margin-top: 5px;
          width: 48px;
          height: 48px;
          background-color: $ec-green;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 16px;
          line-height: 24px;
        }
        .description{
          span{
            color: rgba(22, 22, 22, 0.5);
          }
          p{
            font-size: 16px;
            line-height: 24px;
            margin-top: 10px;
          }
          &.adapt{
            display: none;
            @media (max-width: 1100px){
              display: block;
              margin-bottom: 10px;
            }
          }
        }
      }
      .left{
        @media (max-width: 1100px){
          .description{
            display: none;
          }
        }
      }
    }
  }
}



.search-block{
  position: relative;
  margin-bottom: 20px;
  position: relative;
  .map-btn{
    position: absolute;
    right: 9px;
    top: 9px;
    z-index: 2;
  }
  .zoom-map-icon{
    position: absolute;
    left: 22px;
    top: 16px;
    z-index: 2;
  }
}

.ec-input:deep{
  input{
    background: white;
    font-size: 16px;
    padding: 16px 22px;
    padding-left: 62px;
  }
}

.dadata:deep{
  top: 60px;
}

.map-btn{
  padding: 8px 16px;
  background: #E7E7E7;
  border-radius: 14px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  color: #424242;
}

.empty-content{
  background: white;
  font-size: 22px;
  line-height: 22px;
  padding: 24px;
  text-align: center;
  border-radius: 16px;
  margin-top: 20px;
}

.no-info{
  background: $ec-grey;
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 30px;
  width: fit-content;
  color: rgba(22, 22, 22, 0.5);
}

.loading {
  font-size: 18px;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1em;
  }
}
</style>
