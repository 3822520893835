<template>
  <div class="item">
    <div class="info">
      <img :src="require(`@/assets/images/${report.status === 'Вывоз выполнен' ? 's' : 'f'}-pick.svg`)">
      <span>{{ getDate(report.time_visit) }}</span>
    </div>
    <div @click="isShowECContainersReport = true" class="map-btn">Отчет</div>
  </div>

  <transition name="default">
    <ECContainersReport
      v-if="isShowECContainersReport"
      @close="isShowECContainersReport = false"
      :report="report"
    />
  </transition>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import moment from 'moment'
import ECContainersReport from '@/components/modals/ECContainersReport.vue'

const props = defineProps({
  report: {
    type: Object,
    required: true,
  },
})

const isShowECContainersReport = ref(false)

const getDate = (time) => {
  return moment(time).add(2, 'hours').format('DD.MM.YYYY ・ HH:mm')
}
// const emit = defineEmits(['close', 'setOrder'])

</script>

<style scoped lang="scss">
.item{
  background: $ec-grey;
  padding: 8px 8px 8px 16px;
  border-radius:  16px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  .info{
    display: flex;
    align-items: center;
    img{
      margin-right: 10px;
    }
  }
}
.map-btn{
  padding: 8px 16px;
  background: #E7E7E7;
  border-radius: 14px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  color: #424242;
}
</style>
