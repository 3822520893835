<template>
  <ECModal
    @close="$emit('close')"
    widthContent
  >
    <div class="content">

      <div class="title">Отчет № {{ report.number }}</div>

      <div class="time">{{moment(report.time_visit).add(2, 'hours').format('DD.MM.YYYY ・ HH:mm')}}</div>

      <div class="image">
        <img v-if="report.photo[order]" class="img" :src="report.photo[order]" alt="">

        <div v-else class="empty-gallery">
          <span>Фото отсутствует</span>
        </div>

        <img
          class="arrow prev"
          src="@/assets/images/arrow-gallery.svg"
          @click="order = 0"
          v-if="order === 1 && report.photo.length"
        >

        <img
          class="arrow next"
          src="@/assets/images/arrow-gallery.svg"
          @click="order = 1"
          v-if="order === 0 && report.photo.length > 1"
        >

      </div>

      <div class="report-info">
        <div class="left">
          <div class="item">
            <span>Госномер авто</span>
            <p>{{ report.state_number }}</p>
          </div>
          <div class="item">
            <span>Комментарий водителя</span>
            <p>{{ report.commnet }}</p>
          </div>
        </div>
        <div class="right">
          <div class="item">
            <span>Причина невывоза</span>
            <p>{{ report.reason }}</p>
          </div>
          <div class="item">
            <span>Статус</span>
            <p>{{ report.status }}</p>
          </div>
        </div>
      </div>

    </div>
  </ECModal>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import moment from 'moment'

const props = defineProps({
  report: {
    type: Object,
    required: true,
  }
})
const emit = defineEmits(['close'])

const order = ref(0)
</script>

<style scoped lang="scss">
:deep(.ec-modal__content){
  overflow-x: hidden;
}
.content{
  width: 600px;
  @media (max-width: 640px){
    width: calc(100vw - 40px);
  }
}
.title{
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.01em;
  margin-top: -35px;
  margin-left: 8px;
}
.time{
  font-size: 16px;
  line-height: 20px;
  margin-top: 5px;
  margin-left: 8px;
  margin-bottom: 15px;
}
.image{
  overflow-x: hidden;
  position: relative;
  border-radius: 16px;
  margin-bottom: 20px;
  transition: ease .3s;
  .img{
    width: -webkit-fill-available;
    height: auto;
    display: block;
  }
  .arrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    left: 10px;
    cursor: pointer;

    &.next{
      left: auto;
      right: 10px;
      transform: translateY(-50%) rotate(0deg);
    }
  }
}

.report-info{
  display: flex;
  margin-bottom: 10px;
  gap: 0 20px;
  .left,.right{
    width: 50%;

    .item{
      padding-bottom: 20px;
      border-bottom: 1px solid #F3F3F3;
      margin-bottom: 18px;
      &:last-child{
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
      span{
        color: rgba(22, 22, 22, 0.5);
      }
      p{
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
      }
    }
  }
}

.empty-gallery{
  width: 100%;
  background: $ec-grey;
  height: 400px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  span{
    font-size: 16px;
    line-height: 24px;
    opacity: 0.5;
  }
}
</style>
